import React, { useState }  from "react"
import axios from "axios";
import { Link } from "gatsby"


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
    import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { IoMailOutline } from "@react-icons/all-files/io5/IoMailOutline";
import { IoCheckmarkCircleOutline } from "@react-icons/all-files/io5/IoCheckmarkCircleOutline";



import thankYouCSS from  '../components/thankyounote.css';

const MyForm = () => {

    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });




const [showSend, setShowSend] = useState(0);
const handleChange = () => {

if (showSend==0) {setShowSend(true); }
if (showSend==1) {setShowSend(false); }


};

const [showThankYou, setshowThankYou] = useState(0);

const handleChangeSend = () => {



if (showThankYou==0) {setshowThankYou(true); }
if (showThankYou==1) {setshowThankYou(false); }



};


    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/85bf0b44-311e-4498-b400-256197c56ef2",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);handleChangeSend();
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
        <div>


  {!showThankYou ? (



    


    <Box
  className='contactBox'
      noValidate
      autoComplete="off"
    >

                <form onSubmit={handleOnSubmit}>

<Grid container spacing={2}>

  <Grid item md={6} xs={12}>

        <TextField
          name="name" id="name" type="text" className="form-control"
            floatingLabelText="Name"
          label="Please tell me your name"
          multiline
          maxRows={4}
          variant="filled"
        />

</Grid>
  <Grid  className='animate__animated fadeIn' item md={6} xs={12}>

        <TextField
           name="email" 
           id="email" 
           type="email" 
           className="form-control"
            floatingLabelText="Email"
          label="Kindly provide an email address"
          multiline
          variant="filled"
        />

</Grid>

  <Grid  item xs={12}>


        <TextField
          name="message" id="message" className="form-control"
          label="Please write your message here"
          multiline
          rows={4}
          variant="filled"
        />
      


</Grid>






  <Grid className='checksandbalances animate__animated' item xs={12}>

<FormControlLabel className='animate__animated fadeIn' required control={<Switch        onChange={handleChange}      />} label="I accept the terms and conditions." />

</Grid>








  <Grid item xs={12}>


   <div id='sendHolder' class="text-center">


   
    {showSend ? (


    <Button endIcon={<IoMailOutline />} id='sendButton' className={showSend ? 'buttonActive' : null}  type="submit" variant="outlined">
        Send
      </Button>


      ) : (<div></div>)}



</div>



</Grid>
</Grid>



</form>







    </Box>











      ) : (<div></div>)}

  {showThankYou ? (



<div className='thankYouNote'>
      <div className='checkmarkContact'><IoCheckmarkCircleOutline/></div>

     <span className='thankYouEmail'>Thank you most kindly for your email.</span>
</div>


      ) : (<div></div>)}









  </div>

    );
  };

  export default MyForm;

