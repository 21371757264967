import * as React from "react"

import Seo from "../components/seo"
import Slider from "../components/coretalk"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
       title='Talk | E.O. Chirovici'
        
        description="Eugen Ovidiu Chirovici's personal chatbot, where you may talk with E.O. Chirovici or contact Eugen Oviidu Chirovici  "

        keywords='Eugen Ovidiu Chirovici, The Book of Mirrors, E.O. Chirovici, Eugen Chirovici, Bad Blood, Sleeping Dogs, Rumours, Gods Weapons and Money, Eugene Chirovici'


      />

<Helmet>

</Helmet>



<Slider/>
     





    </div>
  )

}


export default mainPage;