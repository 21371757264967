import React, { Component, useState } from 'react';

import ChatBot from 'react-simple-chatbot';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { IoMailOutline } from "@react-icons/all-files/io5/IoMailOutline";

import { Link } from "gatsby"


import ChatBotForm from  '../components/chatbotform';
import CoreSearch from  '../components/searchjs.js';


import thankYouCSS from  '../components/thankyounote.css';






var password = 0;



const numA =  Math.floor(Math.random() * 3); 

const numB =  Math.floor(Math.random() * 3); 

const totalNum = numA**numB;

const totalNum2 = totalNum - 1;
const totalNum3 = totalNum + 1;


const totalNumString = totalNum.toString();
const totalNum2String = totalNum2.toString();
const totalNum3String = totalNum3.toString();

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}




var optionArray = 

      [
          { value: 1, label: `${totalNum2String}`, trigger: 'verificationfail' },
          { value: 2, label: `${totalNumString}`, trigger: 'verificationsuccess' },
          { value: 3, label: `${totalNum3String}`, trigger: 'verificationfail' },

 
        ];


var optionArrayBiology = 

      [
          { value: 1, label: `Florida`, trigger: 'verificationsuccess' },
          { value: 2, label: `Alaksa`, trigger: 'verificationfail3' },
          { value: 3, label: `New Hampshire`, trigger: 'verificationfail' },

 
        ];
var optionArrayPhysics = 

      [
          { value: 1, label: `Seaside resort`, trigger: 'verificationsuccess' },
          { value: 2, label: `Ski lodge`, trigger: 'verificationfail' },
          { value: 3, label: `Exotic locations`, trigger: 'verificationfail' },

 
        ];
var optionArrayChemistry = 

      [
          { value: 1, label: `Happier`, trigger: 'verificationsuccess' },
          { value: 2, label: `Indigestion`, trigger: 'verificationfail' },
          { value: 3, label: `Married`, trigger: 'verificationfail2' },

 
        ];

var optionArrayEconomics = 

      [
          { value: 1, label: `Long Gamma`, trigger: 'verificationsuccess' },
          { value: 2, label: `Short Theta`, trigger: 'verificationfail4' },
          { value: 3, label: `Binomial`, trigger: 'verificationfail' },

 
        ];

shuffleArray(optionArray);
shuffleArray(optionArrayBiology);
shuffleArray(optionArrayChemistry);
shuffleArray(optionArrayPhysics);
shuffleArray(optionArrayEconomics);


var stringTime='';


function getTime () { 

let currentDate = new Date();


const currentTime = currentDate.getHours(); 

if       (currentTime > 18) { stringTime = 'Good evening.';  }
else if  (currentTime > 14) { stringTime = 'Good afternoon.';  }
else if  (currentTime > 10) { stringTime = 'Good day.';  }
else                 { stringTime = 'Good morning.';  }

}




getTime();


const greeting = stringTime.toString();



const fullNumberLabel = 'Please be so kind as tell me what ' + numA + ' to the power ' + numB + ' is.' 

const fullNumberLabelString = fullNumberLabel.toString();











const chatLog = 

[


      {
        id: '1',
        message:`${greeting}`,
        trigger: 'entry'
      },

  

      {
        id: 'entry',
                delay:1000,

        message: 'How may I help?',
        trigger: 'paths'
      },

    {
        id: 'entrysecondary',
        delay:2000,
        message: 'How may I be of further assistance?',
        trigger: 'paths'
      },

      
      {
        id: 'paths',
        options: [
          { value: 1, label: 'I would like to talk with you', trigger: 'talkentry' },
          { value: 2, label: 'I would like to find something ', trigger: 'findentry' },
          { value: 3, label: 'I would like to get updates about you ', trigger: 'updatesentry' },
          { value: 4, label: 'I would like to send you an email', trigger: 'contactentry' },
 

        ],
      },





   {
        id: 'updatesentry',
        message: `I'm an open book, as it were. `,
        trigger: 'updatesentry2'
   },

  {
        id: 'updatesentry2',
        delay:2000,
        message: "Please use the website's updates section",
        trigger: 'entrysecondary'
      },















   {
        id: 'findentry',
        delay:2000,
        message: `By all means.`,
        trigger: 'findentry2'
   },

   {
        id: 'findentry2',
        message: `First, here is a list of all of the pages on the website.`,
        trigger: 'findentry3'
   },
   {
        id: 'findentry3',

 component: (


        <div className='chatSearch'>


<Link to='/'>Home    </Link>
<Link to='/about'>About    </Link>
<Link to='/books'>Books    </Link>
<Link to='/talk'>Talk    </Link>
<Link to='/search'>Search    </Link>
<Link to='/books/book-of-mirrors'>Book of Mirrors    </Link>

<Link to='/books/bad-blood'>Bad Blood    </Link>
<Link to='/books/rumours'>Rumours    </Link>
<Link to='/books/gods-weapons-and-money'>Gods Weapons & Money    </Link>



        </div>



         ),


        trigger: 'findentry4'
   },







   {
        id: 'findentry4',
        delay:4000,
        message: `Now, would you like me to bring up the search module itself?`,
        trigger: 'findentry5'
   },




    {
        id: 'findentry5',
        options: [
          { value: 1, label: "No, it's fine", trigger: 'entrysecondary' },
          { value: 2, label: 'Yes, I am afraid so', trigger: 'findentry6' },


        ],
      },





   {
        id: 'findentry6',
        delay:2000,
        message: `Just a moment please`,
        trigger: 'findentry7'
   },




   {
        id: 'findentry7',

 component: (


<CoreSearch />



         ),


        trigger: 'entrysecondary'
   },









 {
        id: 'contactentry',
        message: `I'm always very happy to hear from my readers. `,
        trigger: 'contactentry2'
   },



 {
        id: 'contactentry2',
        delay:3000,

        message: `However, I just need to check that you are not a bot such as myself. `,
        trigger: 'contactentry3'
   },

 {
        id: 'contactentry3',
        delay:3000,

        message: `${fullNumberLabelString}`,
        trigger: 'contactverification'
   },

   
      {
        id: 'contactverification',
        options: optionArray      


      },





   {
        id: 'verificationfail',
        message: "I am afraid that is not correct. You may neverthless try a question from a different field",
        trigger: 'contactverificationFields'
   },
   {
        id: 'verificationfail2',
        message: "Your customs amaze me. You may neverthless try another question from a different field",
        trigger: 'contactverificationFields'
   },
      {
        id: 'verificationfail3',
        message: "That is not the case. You may neverthless try a question from a different field",
        trigger: 'contactverificationFields'
   },

         {
        id: 'verificationfail4',
        message: "For some, it may very well have had. As such, you may try a question from a different field",
        trigger: 'contactverificationFields'
   },

      {
        id: 'contactverificationFields',
        options: [
          { value: 1, label: 'Biology', trigger: 'contactverificationBiology' },
          { value: 2, label: 'Chemistry', trigger: 'contactverificationChemistry' },
          { value: 3, label: 'Physics', trigger: 'contactverificationPhysics' },
          { value: 4, label: 'Economics', trigger: 'contactverificationEconomics' },
          { value: 5, label: 'No, it is fine.', trigger: 'entrysecondary' }

        ],
      },



   {
        id: 'contactverificationBiology',
        message: "An average person would associate the Kerbs cycle with which U.S. state?",
        trigger: 'contactverificationBiology2'
   },




      {
        id: 'contactverificationBiology2',
        options: optionArrayBiology      


      },



   {
        id: 'contactverificationChemistry',
        message: "If an average person eats an aromatic ring they may get..",
        trigger: 'contactverificationChemistry2'
   },




      {
        id: 'contactverificationChemistry2',
        options: optionArrayChemistry      


      },




   {
        id: 'contactverificationPhysics',
        message: "An average person would associate the Schrödinger equation with a holiday to which location?",
        trigger: 'contactverificationPhysics2'
   },




      {
        id: 'contactverificationPhysics2',
        options: optionArrayPhysics      


      },


   {
        id: 'contactverificationEconomics',
        message: "After the Industrial Revolution, life got more ",
        trigger: 'contactverificationEconomics2'
   },




      {
        id: 'contactverificationEconomics2',
        options: optionArrayEconomics     


      },





   {
        id: 'verificationsuccess',
        message: "Thank you! That is correct.",
        trigger: 'contactForm1'
   },





   {
        id: 'contactForm1',
        message: "Please be so kind as to use the form below. ",
        trigger: 'contactForm2'
   },
 






  {
        id: 'contactForm2',
        delay:2000,
        component: (


<ChatBotForm/>


         ),

        trigger: 'contactForm3'
      },


  {
        id: 'contactForm3',
        delay:2000,

        message: "I will get back to you as soon as I can.",
        trigger: 'entrysecondary'
   },













    {
        id: 'talkentry',
        message: "What would you like to talk about?",
        trigger: 'menu'
      },

      {
        id: 'menu',
        options: [
          { value: 1, label: 'About you', trigger: 'about' },
          { value: 2, label: 'New books', trigger: 'newBooks' },
          { value: 3, label: 'Past books', trigger: 'pastBooks' },
          { value: 4, label: 'Sleeping Dogs', trigger: 'sleepingdogs' },
          { value: 5, label: 'Any news you may have', trigger: 'updates' }

        ],
      },



//PastBooks




    {
        id: 'sleepingdogs',
        delay:2000,

        message: "The film is an adaptation of the Book of Mirrors and I very much look forward to it myself.",
        trigger: 'sleepingdogs2'
      },


    {
        id: 'sleepingdogs2',
        delay:4000,
        message: "It was amazing news to hear Russell Crowe,Tommy Flanagan or Karen Gillan would be participating. ",
        trigger: 'sleepingdogs3'
      },



    {
        id: 'sleepingdogs3',
                delay:4000,

        message: "The film is due to be released on March 2024 and you may see the trailer here on my website. ",
        trigger: 'sleepingdogs4'
      },




    {
        id: 'sleepingdogs4',
                delay:4000,

        message: "Kindly follow my Facebook page for more news. ",
        trigger: 'entrysecondary'
      },







   {
        id: 'pastBooks',
        message: 'Fiction or non-fiction?',
        trigger: '6'
    },
   {
        id: '6',
        options: [
          { value: 1, label: 'Fiction', trigger: 'pastFiction1' },
          { value: 2, label: 'Non-Fiction', trigger: 'pastNonFiction1' }
        ],
      },




//PastFictionBooks




   {
        id: 'pastFiction1',
        message: 'I wrote several fiction books. ',
        trigger: 'pastFiction2'
    },
   {
        id: 'pastFiction2',
        message: 'Here is a selection of my favorites: ',
        trigger: 'pastFictionOptions'
    },
       {
        id: 'pastFictionOptions',
        options: [
          { value: 1, label: 'Book of Mirrors', trigger: 'bookOfMirrors' },
          { value: 2, label: 'Bad Blood', trigger: 'badBlood' },
          { value: 4, label: 'No, something else', trigger: 'entry' },
          { value: 5, label: 'No, let\'s look at Non-Fiction', trigger: 'pastNonFiction2' }


        ],
      },



   {
        id: 'bookOfMirrors',
        message: 'The Book of Mirrors is my most popular fiction novel. ',
        delay:2000,
        trigger: 'bookOfMirrors2'
    },



   {
        id: 'bookOfMirrors2',
        message: 'It was one of does books that one thinks about for several years while enagged with other matters and then writes almost overnight ',
        delay:4000,
        trigger: 'bookOfMirrors3'
    },

   {
        id: 'bookOfMirrors3',
        message: 'Here is the introduction :  ',
        delay:4000,
        trigger: 'bookOfMirrors4'
    },

  {
        id: 'bookOfMirrors4',
        component: (
        <div className='bookBlurb'>
            <h2>Book of Mirrors</h2>
            <p> 
            When big-shot literary agent Peter Katz receives an unfinished manuscript entitled The Book of Mirrors, he is intrigued.

The author, Richard Flynn is writing a memoir about his time at Princeton in the late 80s, documenting his relationship with the famous Professor Joseph Wieder. One night in 1987, Wieder was brutally murdered in his home and the case was never solved.

Peter Katz is hell-bent on getting to the bottom of what happened that night twenty-five years ago and is convinced the full manuscript will reveal who committed the violent crime. But other people’s recollections are dangerous weapons to play with, and this might be one memory that is best kept buried. </p>

            <Link rel='nofollow' to='/books/book-of-mirrors'> Book of Mirrors </Link>
        </div>
         ),
        trigger: 'pastFictionR'
      },






  {
        id: 'badBlood',
        component: (
        <div className='bookBlurb'>
            <h2>Bad Blood</h2>
            <p> 
            You can't trust your own memories. You can't trust other people's stories either. So how do you know what really happened that night?

Psychologist James Cobb gives a presentation on the art of recovering lost memory on a rainy night in New York. When he leaves, he is approached by a stranger: a dying man, who tells him that, about forty years ago, he woke up in a hotel room with a woman who had been killed, but he does not remember what happened. . Cobb needs to know if he's dealing with an innocent man or a murderer. But the threads of this ancient mystery are much more tangled than they seem at first glance.  </p>

            <a href='https://www.chirovici.com/books/bad-blood'> Bad Blood </a>
        </div>
         ),
        trigger: 'pastFictionR'
      },



   {
        id: 'pastFictionR',
        delay:4000,
        message: 'Would you like to know about other fiction books?',
        trigger: 'pastFictionOther'
    },

    {
        id: 'pastFictionOther',
        options: [
          { value: 1, label: 'No, let\'s look at Non-Fiction', trigger: 'pastNonFiction1' },
          { value: 2, label: 'Yes, the Book of Mirrors', trigger: 'bookOfMirrors' },
          { value: 3, label: 'Yes, Bad Blood', trigger: 'badBlood' },
          { value: 5, label: 'No, something else', trigger: 'entry' }

        ],
      },








//PastNonFictionBooks





   {
        id: 'pastNonFiction1',
        message: 'I wrote several books on academic topics. ',
        trigger: 'pastNonFiction2'
    },
   {
        id: 'pastNonFiction2',
        delay:1000,
        message: 'Here is a some of my favorites: ',
        trigger: 'pastNonFictionOptions'
    },
       {
        id: 'pastNonFictionOptions',
        options: [
          { value: 1, label: 'Rumours', trigger: 'rumours' },
          { value: 2, label: 'Gods, Weapons and Money', trigger: 'godWeaponsAndMoney' },
          { value: 4, label: 'No, something else', trigger: 'entry' },
          { value: 5, label: 'No, let\'s look at Fiction', trigger: 'pastFiction2' }

        ],
      },




  {
        id: 'rumours',
        component: (
        <div className='bookBlurb'>
            <h2>Rumours</h2>
            <p> 
            The aim of the book is to explore the social and cultural impact of rumor from Antiquity to the mid-1990s, examining it as one of the most important contributing factors to violence and discrimination. Usually defined as an unverified account that circulates from one person to another and refers to an object, event, or matter of public interest, rumor and its impact have largely been ignored by scholars and authors. Eugen O. Chirovici has tried not only to describe a number of major historic events, but also to explain how the rumors that influenced them came into being and to account for the collective desires or fears that nurtured them.</p>

            <Link to="books/rumours"> Rumours </Link>
        </div>
         ),
        trigger: 'pastNonFictionR'
      },



  {
        id: 'godWeaponsAndMoney',
        component: (
        <div className='bookBlurb'>
            <h2>God, Weapons and Money</h2>
            <p> 
           In Gods, Weapons and Money, Eugen O. Chirovici takes us on a journey to answer the most basic but profound questions about the sources of human influence. </p>

                        <Link to="books/gods-weapons-and-money"> Gods, Weapons and Money </Link>

        </div>
         ),
        trigger: 'pastNonFictionR'
      },







   {
        id: 'pastNonFictionR',
        delay:3000,

        message: 'Would you like to know about other academic books? ',
        trigger: 'pastNonFictionOther'
    },

    {
        id: 'pastNonFictionOther',
        options: [
          { value: 1, label: 'No, let\'s look at Fiction', trigger: 'pastFiction2' },
          { value: 2, label: 'Yes, Rumours', trigger: 'rumours' },
          { value: 3, label: 'Yes, Gods, Weapons and Money', trigger: 'godWeaponsAndMoney' },
          { value: 5, label: 'No, something else', trigger: 'entry' }

        ],
      },






//About

  {
        id: 'about',
        message: 'Sure. What would you like to know? ',
        trigger: 'aboutMenu'
    },
  {
        id: 'about2',
        delay:3000,
        message: 'Would you like to know something else? ',
        trigger: 'aboutMenu'
    },



       {
        id: 'aboutMenu',
        delay:3000,
        options: [
          { value: 1, label: 'Why did you decide to become a writer?', trigger: 'why' },
          { value: 2, label: 'When did you decide to become a writer?', trigger: 'when' },
          { value: 3, label: 'Which is the book you\'re most proud of?', trigger: 'what' },
          { value: 4, label: 'What are you up to now?', trigger: 'updates' },
          { value: 5, label: 'Are you writing anything new?', trigger: 'newBooks' },
          { value: 6, label: 'What are your influences?', trigger: 'influences' },
          { value: 7, label: 'What else do you do besides to write?', trigger: 'hobbies' },
          { value: 8, label: 'I would like to ask about something else', trigger: 'entry' },
        ],
      },




   {
        id: 'influences',
        delay:2000,
        message: 'It is safe to say that I have a debt of gratitude to many writers.  ',
        trigger: 'influences2'
    },
   {
        id: 'influences2',
        delay:5000,

        message: "Top of my head, I would be highly amiss if I didn't mention authors such as Hemingway, Steinbeck, Camus, Salinger, Frisch, Dürrenmatt, Fowles, Golding and Auster.  ",
        trigger: 'about2'
    },



   {
        id: 'hobbies',
        delay:2000,
        message: "Well, I've only recently become a full-time writer  ",
        trigger: 'hobbies2'
    },
   {
        id: 'hobbies2',
        delay:2000,

        message: "so quite a lot, basically. ",
        trigger: 'hobbies3'
    },

  {
        id: 'hobbies3',
        delay:4000,

        message: "That said, if you mean things I genuinely enjoy, I would have to mention that I've also been painting throughout my life ",
        trigger: 'hobbies4'
    },


  {
        id: 'hobbies4',
        delay:3000,

        message: "and I've also been a life-long practitioner of martial arts. ",
        trigger: 'about2'
    },

















   {
        id: 'why',
        message: 'I am a writer. ',
        trigger: 'why2'
    },
   {
        id: 'why2',
        delay:2000,

        message: 'Perhaps you heard about me because of the success of the Book of Mirrors ',
        trigger: 'why3'
    },

   {
        id: 'why3',
        delay:4000,

        message: 'but I\'ve actually been writing since early youth. It\'s just who I am and what I\'m most naturally dedicated to.',
        trigger: 'why4'
    },

   {
        id: 'why4',
        delay:6000,

        message: 'I actually worked as journalist, helped start a television station, was a public servant and hold several PhDs.',
        trigger: 'why5'
    },
  {
        id: 'why5',
        delay:5000,

        message: 'But, as a person and as a vocation, I am a writer.',
        trigger: 'about2'
    },







  {
        id: 'when',
        message: 'I decided I wanted to be a writer early on. I had just graduated from university',
        trigger: 'when2'
    },

  {
        id: 'when2',
        delay:4000,
        message: 'My first published novel, The Massacre, was popular enough to make a recent graduate happy.',
        trigger: 'when3'
    },

  {
        id: 'when3',
        delay:5000,
        message: 'But this was right after Communism collapsed, in 1990s Romania, so hyperinflation meant that what was supposed to be a small fortune in your early 20s turned into a lot of paper.  ' ,
        trigger: 'when4'
    },
  {
        id: 'when4',
        delay:7000,
        message: 'So I took up a job as a journalist and one thing led to another : a television station, government, all sorts.' ,
        trigger: 'when5'
    },


  {
        id: 'when5',
        delay:5000,
        message: 'I kept writing in my spare time however, publishing several books. Consequently, when I saw the opportunity to write full time, I took it.' ,
        trigger: 'when6'
    },

      {
        id: 'when6',
        delay:8000,
        message: 'So it basically took me 20 years to actually dedicate myself to writing. But the decision had already been made. ' ,
        trigger: 'about2'
    },

  {
        id: 'what',
        message: 'The one I\'m currently writing. More on that later.',
        trigger: 'about2'
    },










//Updates




  {
        id: 'updates',
        message: "I'm currently looking forward to the adaptation of the Book of Mirrors for the cinema.",
        trigger: 'updates2'
    },
   {
        id: 'updates2',
                delay:2000,

        message: "The film is called Sleeping Dogs and it should be in cinemas around March 2024.",
        trigger: 'updates3'
    },

  {
        id: 'updates3',
        delay:4000,

        message: 'Apart from that, I mostly just write.',
        trigger: 'updates3'
    },
  {
        id: 'updates3',
                delay:4000,

        message: 'You may connect with me on Facebook or Twitter to keep in touch.',
        trigger: 'updates4'
    },

  {
        id: 'updates4',
        delay:4000,
        message: 'or you can just use the website\'s updates section.',
        trigger: 'updates5'
    },



  {
        id: 'updates5',
                delay:2000,

        component: (
        <div className='bookBlurb'>
            <p> 
            Updates from Eugen Ovidiu Chirovici : <Link to='/about#updates '>Updates </Link>
            </p>

            
        </div>
         ),
        trigger: 'about2'
      },




  {
        id: 'newBooks',
        message: 'I am writing what I feel is my best book yet. ',
        trigger: 'newBooks2'
    },

  {
        id: 'newBooks2',
        delay:4000,
        message: 'But first I want to make sure all feedback from the Book of Mirrors and Bad Blood is taken care of.',
        trigger: 'aboutMenu'
    },



 









      {
        id: 'restartMenu',
        delay:3000,
        message: 'Happy to help! May I help with anything else?',
        trigger: 'menu'

      },



];




export default function bot() {










return (




<ChatBot

recognitionEnable={false}

steps={chatLog}

botAvatar={'https://www.chirovici.com/images/EugenOvidiuChirovici-min.jpg'}

userAvatar={'https://www.chirovici.com/images/user.png'}

/>



)


};




