import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';



import Chatbot from './chatbot.js';





import "swiper/css";

import {Navigation } from "swiper";

export default function App(props) {

  






  return (

      <>
  <Swiper
        className='transparent chatbot'
        spaceBetween={0}

                centeredSlides={false}



        grabCursor={true}
  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/search');    };

              if (swiperCore.realIndex === 0) {    navigate('/sleeping-dogs');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' transparent'> </SwiperSlide>





            
             <SwiperSlide className='switchToSLC white'> 




<div className='longPage minWidth talk'>



<Chatbot/>






</div>

        </SwiperSlide>





























      <SwiperSlide  className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}